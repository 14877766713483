/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"www.fit-okna.cz"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1o8q25j --style3 --full --parallax" name={"uvod"} fullscreen={true} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/40398/c06609fb182e434596f1b9268c959dab_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/40398/c06609fb182e434596f1b9268c959dab_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/40398/c06609fb182e434596f1b9268c959dab_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/40398/c06609fb182e434596f1b9268c959dab_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/40398/c06609fb182e434596f1b9268c959dab_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/40398/c06609fb182e434596f1b9268c959dab_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/40398/c06609fb182e434596f1b9268c959dab_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/40398/c06609fb182e434596f1b9268c959dab_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--1 flex--center" anim={"2"} animS={"4"} style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="--left pb--02 pl--02 pr--02 pt--02" anim={null} animS={null} style={{"maxWidth":1200}}>
              
              <Title className="title-box title-box--shadow5 title-box--center fs--48" content={"<span style=\"color: var(--color-custom-1--15);\">Servis plastových oken a dveří včetně eurooken\nMontáž a servis žaluzií\nZaměření, instalace rolet a sítí proti hmyzu</span><br>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--center" content={"<span style=\"font-weight: bold; color: var(--color-custom-1--15);\">Veškeré nacenění okamžitě a bezplatně při první kontrole Vašich oken a dveří</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} name={"gp8kvb6w7w"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box fs--36" style={{"maxWidth":974}} content={"<span style=\"font-style: italic; color: var(--color-blend--05);\"><span style=\"font-weight: bold;\"><span style=\"text-decoration-line: underline;\">Profesionální servis plastových oken\n</span><br></span><br>&nbsp;Máte problémy s plastovými okny?\n<br><br><span style=\"text-decoration-line: underline;\">Naše odborná servisní služba je tu pro vás!\n<br></span><br>Nabízíme komplexní servis plastových oken, který zahrnuje:\n<br>Diagnostiku a opravy: <br>Rychle a efektivně identifikujeme a opravíme jakýkoli problém s vašimi okny.\n<br>&nbsp;Zajistíme, aby vaše okna fungovala hladce a efektivně, čímž zvýšíme jejich životnost a energetickou účinnost.\n<br>Výměnu těsnění: Zamezíme průvanu a úniku tepla, což vám ušetří náklady na vytápění.<br><br>&nbsp;Poskytneme vám odborné rady a doporučení pro optimální péči o vaše okna.\n<br><span style=\"text-decoration-line: underline;\">&nbsp;Proč si vybrat nás?\n</span><br>&nbsp;Rychlá reakce: Na vaše požadavky reagujeme rychle a flexibilně.\n<br><br>&nbsp;<span style=\"text-decoration-line: underline;\">Kvalifikovaní technici: </span><br>Naši technici jsou vyškolení profesionálové s dlouholetou praxí.\n<br>Používáme pouze kvalitní náhradní díly a materiály.<br>Naším cílem je vaše spokojenost a pohodlí. <br><br><span style=\"text-decoration-line: underline;\">Kontaktujte nás ještě dnes a zajistěte si bezproblémový provoz vašich plastových oken!</span></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-n78827 --style3 --full --parallax" name={"uvod"} fullscreen={true} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/7c6da34c55634deea67b58424f5aaae1_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/7c6da34c55634deea67b58424f5aaae1_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/7c6da34c55634deea67b58424f5aaae1_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/7c6da34c55634deea67b58424f5aaae1_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/7c6da34c55634deea67b58424f5aaae1_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/7c6da34c55634deea67b58424f5aaae1_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/7c6da34c55634deea67b58424f5aaae1_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/7c6da34c55634deea67b58424f5aaae1_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3 pb--16 pt--10" style={{"maxWidth":1200}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--24 w--600 swpf--uppercase" content={"<span style=\"font-style: italic;\"><span style=\"color: rgb(255, 212, 9);\">Kontakt&nbsp;<br><br>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; fit-okna@seznam.cz&nbsp;<br></span><br>f</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center fs--30" content={"Ceník - <br>individuální dle stavu Vašich oken "}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/"} content={"<span style=\"color: var(--color-custom-1--15);\">Úvod</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/sluzby"} content={"<span style=\"color: var(--color-custom-1--15);\">Služby</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/reference"} content={"<span style=\"color: var(--color-custom-1--15);\">Reference</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/kontakt"} content={"<span style=\"color: var(--color-custom-1--15);\">Kontakt</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}